import auth0 from 'auth0-js'
import history from '../history'

const ONE_SECOND_MS = 1000
const ONE_MINUTE_S = 60
const ONE_MINUTE_MS = ONE_MINUTE_S * ONE_SECOND_MS
const FIFTEEN_MINUTES = 15
const FIFTEEN_MINUTES_MS = FIFTEEN_MINUTES * ONE_MINUTE_MS


export default class Auth {
  // all other scopes are assigned through a rule via permissions
  requestedScopes = 'openid profile email'
  tokenRenewalTimeout = false

  constructor() {
    const {hostname, origin} = window.location
    const options = {
      clientID: 'XrG2zP3Vsjln-uYJ-ngwcN-dElrN4yf3',
      domain: 'energieonderbrekingen.eu.auth0.com',
      redirectUri: 'https://energieonderbrekingen.nl/callback',
      returnTo: 'https://energieonderbrekingen.nl/home',
      audience: 'https://energieonderbrekingen.nl/api/v1',
      responseType: 'token id_token',
      scope: this.requestedScopes
    }

    if (hostname.indexOf('test.energieonderbrekingen') > -1 ||
        hostname.indexOf('dev.energieonderbrekingen') > -1 ||
        hostname.indexOf('localhost') > -1) {

      options.clientID = 'Wo1uhN60KtO7hSE7ceigYCwCU5Wt3xW3'
      options.domain = 'test-energieonderbrekingen.eu.auth0.com'
      options.audience = 'https://test.energieonderbrekingen.nl/api/v1'
      options.returnTo = origin + '/home'
      options.redirectUri = origin + '/callback'
    }

    this.options = options

    this.auth0 = new auth0.WebAuth(options)
    this.scheduleRenewal()
  }

  login = () => {
    localStorage.setItem('backTo', window.location.pathname)
    this.auth0.authorize()
  }

  gotoPreviousPage = () => {
    if (localStorage.hasOwnProperty('backTo')) {
      history.replace(localStorage.getItem('backTo'))
      localStorage.removeItem('backTo')
    } else {
      history.replace('/home')
    }
  }

  handleAuthentication = () =>
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult)
      } else if (err) {
        history.replace('/home')
        console.log(err) // eslint-disable-line
      }
    })

  getAccessToken = () => {
    const accessToken = localStorage.getItem('access_token')

    if (!accessToken) {
      throw new Error('No Access Token Found')
    }

    return accessToken
  }

  renewToken = () => {
    localStorage.setItem('backTo', window.location.pathname)
    this.auth0.checkSession({}, (err, result) => {
      if (err) {
        history.replace('/home')
        console.log(err) // eslint-disable-line
      } else {
        this.setSession(result)
      }
    })
  }

  scheduleRenewal() {
    clearTimeout(this.tokenRenewalTimeout)
    const expiresAt = this.getExpiresAt()
    const expiresIn = expiresAt - Date.now()

    if (expiresIn < 0) return

    if (expiresIn < FIFTEEN_MINUTES_MS) {
      this.renewToken()

      return
    }

    this.tokenRenewalTimeout = setTimeout(() => {
      this.renewToken()
    }, FIFTEEN_MINUTES_MS)
  }

  getProfile = cb => {
    let accessToken = this.getAccessToken()

    this.auth0.client.userInfo(accessToken, (err, profile) => {
      if (profile) {
        this.userProfile = profile
      }
      cb(err, profile)
    })
  }

  setSession = authResult => {
    // Set the time that the Access Token will expire at
    let expiresAt = JSON.stringify((authResult.expiresIn * ONE_SECOND_MS) + new Date().getTime())
    const scopes = authResult.scope || this.requestedScopes || ''

    localStorage.setItem('access_token', authResult.accessToken)
    localStorage.setItem('id_token', authResult.idToken)
    localStorage.setItem('expires_at', expiresAt)
    localStorage.setItem('scopes', JSON.stringify(scopes))

    this.scheduleRenewal()

    this.gotoPreviousPage()
  }

  logout = () => {
    const {returnTo} = this.options

    // Clear Access Token and ID Token from local storage
    localStorage.removeItem('access_token')
    localStorage.removeItem('id_token')
    localStorage.removeItem('expires_at')
    localStorage.removeItem('scopes')

    clearTimeout(this.tokenRenewalTimeout)
    // // navigate to the home route
    // history.replace('/home')
    this.auth0.logout({ returnTo})
  }

  isAuthenticated = () => {
    // Check whether the current time is after
    // the Access Token's expiry time

    return new Date().getTime() < this.getExpiresAt()
  }

  getExpiresAt = () => {
    return JSON.parse(localStorage.getItem('expires_at'))
  }

  grantedScopes = () => (JSON.parse(localStorage.getItem('scopes')) || '').split(' ')

  userHasScopes = scopes =>  {
    const grantedScopes = this.grantedScopes()

    return scopes.every(scope => grantedScopes.includes(scope))
  }

  getUserDso = () => {
    return this.grantedScopes()
      .filter(s => s.indexOf('read:dso_') === 0)
      .map(s => s.substring('read:dso_'.length))
      .map(s => s.charAt(0).toUpperCase() + s.slice(1))
  }
}
